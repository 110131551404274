

























































































































import GET_OUTLET from "@/modules/outlets/graphql/GetOutlet.gql";
import CREATE_OUTLET from "@/modules/outlets/graphql/CreateOutlet.gql";
import UPDATE_OUTLET from "@/modules/outlets/graphql/UpdateOutlet.gql";
import UIState from "@/modules/outlets/state/ui";
import { Timezones } from "@/modules/common/enums";
import { translate } from "@/i18n";
import { useFormErrors } from "@/composable";
import { defineComponent, ref, watch, onMounted } from "@vue/composition-api";
import {
  LoadingZone,
  FormControl,
  Input,
  InputTag,
  Select,
  InlineSelectorGroup,
  InlineSelector,
  Button,
  Toast,
} from "@/design-system";
import { DeliverySchedule } from "@/v2/enum/ordering-schedule.enum";
import { string } from "@/v2/util/prop/type";
import { is } from "ramda";

export default defineComponent({
  name: "Details",
  components: {
    LoadingZone,
    FormControl,
    Input,
    Select,
    InlineSelectorGroup,
    InlineSelector,
    Button,
    InputTag,
  },
  props: {
    businessId: string().isRequired().use(),
    outletId: string().use(),
  },
  setup(props, context) {
    const { isCreatingOutlet, setOutletId, setOutletName } = UIState;
    const isLoadingButton = ref<boolean>(false);

    const outlet = ref({
      name: null as string | null,
      emails: [] as Array<string>,
      phone: null as string | null,
      timezone: null as string | null,
      taxRate: null as string | null,
      deliverySchedule: DeliverySchedule.BOTH,
      taxIncluded: false,
      voiceEnabled: false,
    });

    // TODO: replace by apollo-composable when available
    const isFetchingOutlet = ref<boolean>(false);
    const fetchOutlet = async () => {
      if (props.outletId) {
        isFetchingOutlet.value = true;

        const { data } = await context.root.$apollo.query({
          query: GET_OUTLET,
          variables: {
            outletId: props.outletId,
          },
        });

        isFetchingOutlet.value = false;

        outlet.value = data?.outlet;
      }
    };

    onMounted(() => {
      fetchOutlet();
    });

    // init form errors
    const {
      clearErrors,
      setErrors,
      messageErrorFor,
      hasErrorFor,
      gqlValidationErrorsExtractor,
    } = useFormErrors();

    const submitForm = async () => {
      isLoadingButton.value = true;

      try {
        await (isCreatingOutlet.value ? createOutlet() : editOutlet());

        setOutletName(outlet.value.name || "");
        clearErrors();

        new Toast().create({
          type: "success",
          title: translate("label.success"),
          text: translate("c.outlets.success_message"),
        });
      } catch (error: any) {
        setErrors(gqlValidationErrorsExtractor(error));

        new Toast().create({
          type: "error",
          title: translate("label.oops"),
          text: translate("c.outlets.error_message"),
        });
      } finally {
        isLoadingButton.value = false;
      }
    };

    const createOutlet = async () => {
      const { data } = await context.root.$apollo.mutate({
        mutation: CREATE_OUTLET,
        errorPolicy: "none",
        variables: {
          businessId: props.businessId,
          ...getOutletAttributes(),
        },
      });

      const outletId = data.createOutlet.id;

      context.root.$router.push({
        name: "outlets.address",
        params: {
          businessId: props.businessId,
          outletId: outletId,
        },
      });

      setOutletId(outletId);

      return data;
    };

    const editOutlet = async () => {
      const { data } = await context.root.$apollo.mutate({
        mutation: UPDATE_OUTLET,
        errorPolicy: "none",
        variables: {
          outletId: props.outletId,
          ...getOutletAttributes(),
        },
      });

      return data;
    };

    const updateOrderingSchedule = (status: DeliverySchedule) => {
      outlet.value = { ...outlet.value, deliverySchedule: status };
    };

    const getOutletAttributes = () => ({
      name: outlet.value.name,
      emails: outlet.value.emails,
      phone: outlet.value.phone,
      taxRate: is(Number, outlet.value.taxRate) ? outlet.value.taxRate : null,
      taxIncluded: outlet.value.taxIncluded,
      voiceEnabled: outlet.value.voiceEnabled,
      deliverySchedule: outlet.value.deliverySchedule,
      timezone: outlet.value.timezone,
    });

    watch(
      () => props.outletId,
      (val) => {
        if (val) {
          fetchOutlet();
        }
      }
    );

    return {
      // state
      isLoadingButton,
      isFetchingOutlet,
      timezones: Timezones,
      outlet,
      DeliverySchedule,

      // actions
      submitForm,
      messageErrorFor,
      hasErrorFor,
      translate,
      updateOrderingSchedule,
    };
  },
});
